import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import Vant from 'vant';
import 'element-plus/dist/index.css'
import zhCN from 'element-plus/dist/locale/zh-cn.mjs'
import 'vant/lib/index.css';
import './assets/css/element-variables.scss'
import './assets/css/main.scss'
import * as implement from './implement'
import * as Api from './api'

// 执行自适应适配算法
implement.getScreenPx()
implement.watchWindowResize({
    key: 'changeFontSize',
    callback: () => {
        if (window.orientation) {
            setTimeout(function () {
                implement.getScreenPx()
            }, 300)
        } else {
            implement.getScreenPx()
        }
    }
})

const app = createApp(App)

app.config.globalProperties.implement = implement
app.config.globalProperties.Api = Api

app.use(store)
app.use(router)
app.use(Vant)
app.use(ElementPlus, {locale: zhCN})
app.mount('#app')

