import { post, get, del, put, postJSON, encode, downloadBlob } from './implement.js'
import { ElMessage, ElMessageBox } from 'element-plus'

let APIPATH = ''

switch (process.env.VUE_APP_CURENV) {
    case 'dev':
        APIPATH = '/api'
        break
    case 'prod':
        APIPATH = location.origin + '/api'
        break
    default:
        APIPATH = '/api'
        break
}

function getKey(callback) {
    get(APIPATH + '/user/key').then((data) => {
        callback(data)
    })
}

function logout(callback) {
    post(APIPATH + '/user/logout').then(() => {
        callback('success')
    }).catch((error) => {
        console.log('ERROR：' + JSON.stringify(error))
    })
}

function login(params, callback) {
    getKey((data) => {
        let formData = {
            message: encode(JSON.stringify({
                username: params.username,
                password: params.password
            }), data.key)
        }
        get(APIPATH + '/user/login', formData).then((data) => {
            callback(data)
        }).catch((error) => {
            console.log('ERROR：' + JSON.stringify(error))
            callback(error)
        })
    })
}

// 用于GET请求，获取数据
function getData(apiName, params, callback) {
    let query = {...params}
    if (Object.keys(query).length) {
        for (let key in query) {
            if (Array.isArray(query[key])) {
                query[key] = query[key].join(',')
            }
        }
    }
    get(APIPATH + apiName, query).then((data) => {
        callback(data)
    }).catch((error) => {
        callback(error)
    })
}

function postData(apiName, params, callback) {
    //let formData = new FormData() for (let key in params) { formData.append(key, params[key]) }
    postJSON(APIPATH + apiName, params, {
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
    }).then((data) => {
        callback(data)
    }).catch((error) => {
        callback(error)
    })
}

// 用于GET请求，获取字典数据
function getDic(apiName, callback) {
    get(APIPATH + apiName).then((data) => {
        callback(data)
    }).catch((error) => {
        console.log('ERROR：' + JSON.stringify(error))
    })
}

function downloadFile(apiName, params) {
    let a = document.createElement('a')
    a.href = `${APIPATH}${apiName}?${queryData(params)}`
    a.target = '_Self'
    a.click()
    ElMessage.success('正在下载，请稍后...')
}

function queryData(query = {}) {
    let queryString = ''
    if (query) {
        for (let key in query) {
            if (queryString === '') {
                queryString = `${key}=${query[key]}`
            } else {
                queryString += `&${key}=${query[key]}`
            }
        }
    }
    return queryString
}

export {
    login, logout, getData, getDic, postData, downloadFile
}
