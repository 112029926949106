import router from './router/index'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import CryptoJS from 'crypto-js'

let index = 1

function encode(message, key) {
    return CryptoJS.AES.encrypt(message, CryptoJS.enc.Hex.parse(key), {
        iv: CryptoJS.enc.Latin1.parse('Pkcs7'),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).toString().replace(/\+/g, '-').replace(/\//g, '.').replace(/=/g, '~')
}

function getScreenPx() {
    let l = Number(window.innerWidth ? window.innerWidth : document.documentElement.clientWidth)
    let styleID = document.getElementById('styleID')
    l = 1433
    styleID.innerHTML = 'html {font-size: ' + parseFloat(l/14.33).toFixed() + 'px;}'
}

let f = {}

function watchWindowResize(object) {
    if (object.callback) {
        f[object.key] = object.callback
    }
    window.onresize = function () {
        if (Object.keys(f).length) {
            for (let key in f) {
                f[key]()
            }
        }
    }
}

function removeWatchWindow(key) {
    if (Object.keys(f).indexOf(key) >= 0) {
        delete f[key]
    }
}

function formatNumberRgx(num) {
    if (num) {
        let parts = num.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return parts.join('.')
    } else {
        if (num === null) {
            return '-'
        } else {
            return num
        }
    }
}

function getKey() {
    index++
    return new Date().getTime() + index
}

function mockData() {
    let list = []
    for (let i = 1; i <= 10; i++) {
        list.push({
            id: i,
            name: 'test-' + i
        })
    }
    return list
}

function handleEmpty(rule, value, callback) {
    console.log(rule, value)
    if (!value) {
        callback(new Error('不能为空'))
    } else {
        if (value instanceof Array && !value.length) {
            callback(new Error('不能为空'))
        }
        callback()
    }
}

function getOSAndBrowser(data) {
    console.log(data)
    let os = data
    let userAgent = data
    let info = ''
    let tempArray = ''
    // 判断操作系统
    if (os.indexOf('Win') > -1) {
        if (userAgent.indexOf('Windows NT 5.0') > -1) {
            info += 'Win2000'
        } else if (userAgent.indexOf('Windows NT 5.1') > -1) {
            info += 'WinXP'
        } else if (userAgent.indexOf('Windows NT 5.2') > -1) {
            info += 'Win2003'
        } else if (userAgent.indexOf('Windows NT 6.0') > -1) {
            info += 'WindowsVista'
        } else if (userAgent.indexOf('Windows NT 6.1') > -1 || userAgent.indexOf('Windows 7') > -1) {
            info += 'Win7'
        } else if (userAgent.indexOf('Windows NT 6.2') > -1 || userAgent.indexOf('Windows 8') > -1) {
            info += 'Win8'
        } else if (userAgent.indexOf('Windows NT 6.3') > -1 || userAgent.indexOf('Windows 8.1') > -1) {
            info += 'Win8.1'
        } else if (userAgent.indexOf('Windows NT 10.0') > -1 || userAgent.indexOf('Windows 10') > -1) {
            info += 'Win10'
        } else {
            info += 'Other'
        }
    } else if (os.indexOf('Mac') > -1) {
        info += 'Mac'
    } else if (os.indexOf('X11') > -1) {
        info += 'Unix'
    } else if (os.indexOf('Linux') > -1) {
        info += 'Linux'
    } else {
        info += 'Other'
    }
    info += '|'
    // 判断浏览器版本
    let isOpera = userAgent.indexOf('Opera') > -1 // 判断是否Opera浏览器
    let isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera // 判断是否IE浏览器
    let isEdge = userAgent.toLowerCase().indexOf('edge') > -1 && !isIE // 判断是否IE的Edge浏览器
    let isIE11 = (userAgent.toLowerCase().indexOf('trident') > -1 && userAgent.indexOf('rv') > -1)
    if (/[Ff]irefox(\/\d+\.\d+)/.test(userAgent)) {
        tempArray = /([Ff]irefox)\/(\d+\.\d+)/.exec(userAgent)
        info += tempArray[1] + tempArray[2]
    } else if (isIE) {
        let version = ''
        let reIE = new RegExp('MSIE (\\d+\\.\\d+);')
        reIE.test(userAgent)
        let fIEVersion = parseFloat(RegExp['$1'])
        if (fIEVersion === 7) {
            version = 'IE7'
        } else if (fIEVersion === 8) {
            version = 'IE8'
        } else if (fIEVersion === 9) {
            version = 'IE9'
        } else if (fIEVersion === 10) {
            version = 'IE10'
        } else {
            version = '0'
        }
        info += version
    } else if (isEdge) {
        info += 'Edge'
    } else if (isIE11) {
        info += 'IE11'
    } else if (/[Cc]hrome\/\d+/.test(userAgent)) {
        tempArray = /([Cc]hrome)\/(\d+)/.exec(userAgent)
        info += tempArray[1] + tempArray[2]
    } else if (/[Vv]ersion\/\d+\.\d+\.\d+(\.\d)* *[Ss]afari/.test(userAgent)) {
        tempArray = /[Vv]ersion\/(\d+\.\d+\.\d+)(\.\d)* *([Ss]afari)/.exec(userAgent)
        info += tempArray[3] + tempArray[1]
    } else if (/[Oo]pera.+[Vv]ersion\/\d+\.\d+/.test(userAgent)) {
        tempArray = /([Oo]pera).+[Vv]ersion\/(\d+)\.\d+/.exec(userAgent)
        info += tempArray[1] + tempArray[2]
    } else {
        info += 'unknown'
    }
    return info
}

function post(url, params, headers) {
    return new Promise((resolve, reject) => {
        axios.post(url, params, headers).then(res => {
            switch (res.status) {
                case 400:
                    resolve(res.data)
                    break
                default:
                    resolve(res.data)
                    break
            }
        }).catch(err => {
            reject(err.data)
        })
    })
}

function postJSON(url, params, headers) {
    return new Promise((resolve, reject) => {
        axios.post(url, params, headers).then(res => {
            switch (res.status) {
                case 400:
                    resolve(res.data)
                    break
                default:
                    resolve(res.data)
                    break
            }
        }).catch(err => {
            reject(err.data)
        })
    })
}

function get(url, params, headers) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            headers: headers
        }).then(res => {
            switch (res.status) {
                case 400:
                    resolve(res.data)
                    break
                default:
                    resolve(res.data)
                    break
            }
        }).catch(err => {
            reject(err.data)
        })
    })
}

function del(url, params, headers) {
    return new Promise((resolve, reject) => {
        axios.delete(url, params, headers).then(res => {
            switch (res.status) {
                case 400:
                    resolve(res.data)
                    break
                default:
                    resolve(res.data)
                    break
            }
        }).catch(err => {
            reject(err.data)
        })
    })
}

function put(url, params, headers) {
    return new Promise((resolve, reject) => {
        axios.put(url, params, headers).then(res => {
            switch (res.status) {
                case 400:
                    resolve(res.data)
                    break
                default:
                    resolve(res.data)
                    break
            }
        }).catch(err => {
            reject(err.data)
        })
    })
}

function downloadBlob(url, params, headers) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            responseType: 'blob',
            params: params,
            headers: headers
        }).then(res => {
            switch (res.status) {
                case 400:
                    resolve(res.data)
                    break
                case 403:
                    router.replace({
                        path: '/login',
                        query: {
                            redirect: router.currentRoute.fullPath
                        }
                    })
                    break
                default:
                    resolve(res.data)
                    break
            }
        }).catch(err => {
            reject(err.data)
        })
    })
}

axios.defaults.withCredentials = true
axios.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.error(error)
})
axios.interceptors.response.use(response => {
    if (response.status === 200) {
        return Promise.resolve(response)
    } else {
        return Promise.reject(response)
    }
}, error => {
    if (error.response.status) {
        switch (error.response.status) {
            case 401:
                if (router.currentRoute.path !== '/login') {
                    router.replace({
                        path: '/login',
                        query: {redirect: router.currentRoute.fullPath}
                    })
                } else {
                    router.replace({path: '/login'})
                }
                break
            default:
                ElMessage.error(error.response.data.message || '请求失败')
                break
        }
        return Promise.reject(error.response)
    }
})
export {
    getScreenPx, watchWindowResize, removeWatchWindow, mockData, post, postJSON, get, del, put, formatNumberRgx, getKey, downloadBlob, encode, handleEmpty, getOSAndBrowser
}

