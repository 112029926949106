import { createStore } from 'vuex'
import { getData } from '@/api.js'

const STATIC_PATH = location.origin
let API_PATH = ''
let IMAGE_PATH = ''

switch (process.env.VUE_APP_CURENV) {
    case 'dev':
        API_PATH = '/api'
        IMAGE_PATH = 'https://al.pharmacylinked.com'
        break
    case 'prod':
        API_PATH = location.origin + '/api'
        IMAGE_PATH = 'https://al.pharmacylinked.com'
        break
    default:
        API_PATH = '/api'
        IMAGE_PATH = 'https://al.pharmacylinked.com'
        break
}

export default createStore({
    state: {
        base: API_PATH,
        base_image: IMAGE_PATH,
        path: STATIC_PATH,
        user: getUser(),
        dic: {}
    },
    getters: {},
    actions: {
        getUserListDic: function ({
            commit,
            state
        }, callback) {
            getData('/eye/user', '', (data) => {
                if (data.message && data.message === 'Unauthorized.') {
                    callback(false)
                } else {
                    commit('updateDic', {
                        key: 'user',
                        data: data,
                        callback: () => {
                            callback(true)
                        }
                    })
                }
            })
        },
        getItemsDic: function ({
            commit,
            state
        }, callback) {
            getData('/eye/items', '', (data) => {
                commit('updateDic', {
                    key: 'items',
                    data: data,
                    callback: () => {
                        callback ? callback() : ''
                    }
                })
            })
        }
    },
    mutations: {
        setUserInfo: function (state, data) {
            state.user = JSON.parse(JSON.stringify(data))
            state.user['set_time'] = new Date().getTime()
            delete localStorage['agriculture_user']
            localStorage.setItem('agriculture_user', JSON.stringify(state.user))
        },
        clearUserInfo: function (state) {
            state.user = undefined
            localStorage.removeItem('agriculture_user')
        },
        updateDic: function (state, object) {
            state.dic[object.key] = object.data
            setTimeout(() => {
                object.callback()
            })
        }
    }
})

function getUser() {
    let jsonUser = localStorage.getItem('agriculture_user')
    if (jsonUser && jsonUser !== 'undefined') {
        try {
            return JSON.parse(jsonUser)
        } catch (e) {
            return undefined
        }
    } else {
        return undefined
    }
}