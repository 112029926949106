<template>
    <div id="app">
        <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component"/>
            </transition>
        </router-view>
    </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ref, watch } from 'vue'

const router = useRouter()
const route = useRoute()
const store = useStore()

</script>
<style lang="scss">
    #app {
        width: 100%;height: 100%;display: block;overflow: auto;background-color: #F1F2F4;white-space: nowrap;min-width: 14.4rem;

        .main_box.full {
            width: 100%;height: 100%;position: relative;

            .main_box_view {
                height: 100%;
            }
        }
    }
</style>